/* Header */

.sidebar .logo {
  margin: 8px auto;
}
.sidebar .logo2 {
  display: none;
  opacity: 0;
  transition: opacity 0.1s, display 0.1s !important;
}

.sidebar.collapsed .logo2 {
  display: block;
  opacity: 1;
  margin: auto; /* Center the logo2 when collapsed */
}
.sidebar {
  width: 250px;
  border-right: 2px solid #edeff0;
  box-shadow: 0px 8px 25px 0px #00000026;
  font-size: 14px;
  transition: width 0.2s ease;
}

#mainContent {
  background-color: white;
}

.navbar {
  background-color: #ffffff !important;
  color: white !important;
  height: 50px;
  position: sticky;
  z-index: 999;
  border-bottom: 2px solid #e1e2e3;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.notification:hover,
.logout:hover {
  transform: scale(1.1); /* Slightly enlarge icon on hover */
  transition: transform 0.1s ease;
}
/* Hover Effects for Sidebar Items */

.nav-link {
  font-weight: 500 !important;
  padding: 12px !important;
  border-radius: 8px;
}
.nav-link:hover {
  background-color: #4cc8f7;
  color: #fff !important;
  font-weight: 600 !important;
  box-shadow: 0px 4px 20px 0px #00000040;
  transition: all 0.3s ease;
}

.nav-link:hover .sidebaricon {
  transform: scale(1.2); /* Slightly enlarge icon on hover */
  transition: all 0.3s ease; /* Smooth transition for icon scaling */
  color: #ffffff !important;
}

.navbaricon {
  padding: 0px !important;
}
.navbaricon:hover {
  transform: scale(1.1); /* Slightly enlarge icon on hover */
  transition: transform 0.1s ease;
}

.sideNavDropDown {
  width: 100% !important;
  padding: 0px !important;
  border: none;
  background-color: inherit;
  justify-content: left;
}

.topnavDropdown{
  border: none;
  background-color: inherit;
  justify-content: left;
}

.topNavMenu{
  width: fit-content !important;
  padding: 5px;
}

@media (min-width: 576px) {
  .logo2-navbar {
    display: none;
  }
}
.sidebar {
  transition: width 0.1s ease-in;
  z-index: 1000;
  position: sticky;
}

.sidebar.collapsed {
  width: 80px;
  position: sticky;
  z-index: 1000;
  transition: width 0.3s ease;
}

.sidebar.collapsed .nav-link {
  text-align: center;
}

.sidebar.collapsed .nav-link img {
  margin-right: 0;
}

.sidebar.collapsed .nav-item span {
  display: none !important;
}

.sidebar.collapsed .Close {
  display: none;
}

.page-name {
  font-weight: 400;
  font-size: 16px;
}

.menu {
  height: 25px;
  margin-inline: 15px;
}

.sidebar span {
  padding-inline: 10px;
}
.sidebar.collapsed img.logo {
  display: none;
}

@media (max-width: 576px) {
  .sidebar {
    width: 80px;
    position: fixed;
  }

  .sidebar.collapsed {
    display: none !important;
    position: fixed;
  }

  .page-name {
    font-size: 20px;
  }
  .notification,
  .logout {
    margin: 5px !important;
  }

  .footer {
    display: flex;
    flex-direction: column !important;
    margin-left: 0px !important;
    background-color: white;
  }

  .footer .right-part {
    margin: auto !important;
  }

  .page-name {
    font-size: 12px;
  }
}

/* Footer */

/* MainContent */
#mainContent {
  height: inherit;

  /* background: linear-gradient(180deg, #e0f7fa 0%, #ffffff 100%); */
  /* background-color: #f6f7ff !important; */
  background-color: #f1f4fc !important;

  /* background: linear-gradient(180deg, #eae8fc 0%, #ffffff 100%); */
}

/* Inner Content including breadcrumb and cards */
.innerContent {
  margin: 10px;
}

.formCard {
  width: auto;
  border: 2px solid #d7d8d8 !important;
  padding: 20px !important;
  background-color: white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
}
/*Breadcrumb*/
.breadcrumb {
  font-size: 14px;
  font-family: "Poppins" !important;
  margin-bottom: 0 !important;
}

/* InputGroup */

/* inputs */
.formGroup label {
  font-size: 14px !important;
}

.formGroup {
  margin-bottom: 20px;
}
.formGroup1 {
  margin-top: 15px;
}

.formControl {
  border: 2px solid #d2d2d2 !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  height: 40px !important;
}

.textarea {
  border: 2px solid #d2d2d2 !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  /* height: 35px !important; */
}
.formControl:focus {
  outline: none !important;
  background-color: #ffffff;
  color: #333333;
}
.formControl::placeholder {
  color: #808090;
  opacity: 1;
  font-size: 14px !important;
}

/* file */
#formFile::file-selector-button {
  font-size: 14px !important;
  height: inherit;
}

/* buttons */
.btnCTA {
  background-color: #fba834;
  width: 100%;
  height: 40px;
  font-weight: 600;
  transition: all ease 0.2s;
  margin: 5px 0px;
  color: #333 !important;
  border: 0px solid #333;
}

.btnGroup {
  margin-bottom: 20px;
}

/* headings */
.formHeading {
  font-size: 20px !important;
  font-weight: 600;
  margin-top: 0px !important;
  color: #333333;
}

.formSubHeading {
  font-size: 14px !important;
  color: #717171;
  margin-bottom: 10px !important;
}

.resultData {
  border: 2px solid #CDE6E6 !important;
  border-radius: 8px !important;
}

.links {
  text-decoration: underline !important;
  color: #106396 !important;
}

.btnClose {
  background-color: #bb2d3b  !important;
  color: white !important;
  height: 30px;
  width: 30px;
  border-radius: 15px !important;
}

.btnClose:hover {
  background-color:  #87202a !important;
}
.btnCancel {
  background-color: #bb2d3b  !important;
  color: white !important;
}

.btnCancel:hover {
  background-color:  #87202a !important;
}

.btnReset {
  background-color: #e9ecef !important;
  color: #333 !important;
  border: 1px solid #d2d2d2;
}
.btnReset:hover {
  background-color: #d5d8db !important;
}

.btnAddSave {
  background-color: #28a745 !important;
  color: white !important;
}
.btnAddSave:hover {
  background-color: #1e8034 !important;
}

.btnFilter {
  background-color: #6281ff !important;
  color: white !important;
}
.btnFilter:hover {
  background-color: #526cd6 !important;
}

.dropdown-menu {
  width: calc(100% - 30px);
}

.dropdown2 {
  background-color: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  margin-right: 20px;
}
.dropdown2:hover {
  background-color: #4cc8f7 !important;
  color: white !important;
}
.dropdown1 {
  background-color: white;
}
.dropdown1:hover {
  background-color: #4cc8f7 !important;
  color: white !important;
}
.accordion {
  overflow-y: auto;
  overflow-x: hidden; /* Allow vertical scroll if necessary */
}

@media (min-width: 576px) {
  .btnSidebarClose {
    display: none;
  }
}

.count {
  background-color: #4cc8f7;
  font-size: 18px;
  padding-inline: 15px;
  height: 100%;
  color: white;
}

.dropdowns{
  flex-wrap: wrap;
}

.stockDropdown {
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  background-color: inherit;
  justify-content: left;
  color: #333333;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.stockDropdown:hover {
  background-color: #4cc8f7;
  color: #fff !important;
  border: 2px solid #ffffff;
  transition: all 0.3s ease;
}

.stockDropdownMenu {
  width: 70vw !important;
  margin-right: 20px !important;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}

.stockDropdownItem {
  min-width: 250px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  border-radius: 8px;
  margin: 10px;
  overflow: hidden;
}

.isInfoPanelActive {
  background-color: #4cc8f7 !important;
  box-shadow: 0px 4px 25px 0px #00000040;
  border-radius: 8px !important;
  color: white;
  overflow: hidden;
}

.isActive {
  background-color: #4cc8f7 !important;
  box-shadow: 0px 4px 20px 0px #00000040;
  border-radius: 8px !important;
  color: white;
  overflow: hidden;
}

.topbartext{
  color: "#393939" !important;
}

.nav-item.isActive span {
  background-color: #4cc8f7;
  color: #fff !important;
  font-weight: 600 !important;
  transition: all 0.3s ease;
}

.nav-item.isActive .sidebaricon{
  transform: scale(1.2); /* Slightly enlarge icon on hover */
  transition: all 0.3s ease; /* Smooth transition for icon scaling */
  color: #ffffff !important;
}

.editPopup{
  padding: 15px !important;
}


.additionalFiltersAccordion .accordion-button{
  height: 40px !important;
  display: flex;
  align-items: center;
  padding-inline: 0px;
  background-color: inherit !important;
  width: fit-content;
  color: #333333;
}

.additionalFiltersAccordion .accordion-button::after{
margin-inline: 10px;
}

.additionalFiltersAccordion .accordion-body{
  padding: none !important;
}