body {
  background-color: #292929;
}

.card {
  background-color: #1e1e1e;
  max-height: 500px;
  border: 1px solid rgba(224, 224, 224, 0.5);
  border-radius: 33px;
  padding: 0px !important;
  box-shadow: 8px 8px 25px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
}

.card-body {
  box-shadow: 8px 8px 25px rgba(0, 0, 0, 0.5);
  border-radius: 33px;
}

@media only screen and (orientation: landscape) and (min-width: 768px) {
  .card {
    right: 30%;
  }
}

.form {
  font-size: 14px !important;
}

.btn_changePassword {
  max-width: 350px;
  height: 50px;
  border-radius: 30px;
  background-color: #FBA834;
  font-size: 16px;
  margin: 0;
  padding: 0;
  border: none !important;
  color: #292929;
  font-weight: 600;
}

.btn_changePassword:hover {
  background-color: #FBA834;
  color: #ffffff;
  font-weight: 600;
}



.tb_confirmPasswordFieldInput::placeholder,
.tb_newPasswordFieldInput::placeholder {
  font-size: 14px;
  opacity: 25%;
  color: #e0e0e0;
}

.tb_confirmPasswordFieldInput:focus,
.tb_newPasswordFieldInput:focus{
  outline: none !important;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.2);
  background-color: #333333; 
  color: #ffffff;
}

#sub-heading {
  font-size: 18px;
  text-align: left !important;
  margin-bottom: 15px;
  opacity: 75%;
  max-width: 350px !important;
  color: #e0e0e0;
}

.tb_confirmPasswordFieldInput::-ms-value,
.tb_newPasswordFieldInput::-ms-value {
  font-size: 14px !important;
}
