
.card {
  background-color: #1e1e1e;
  max-height: 500px;
  border: 1px solid rgba(224, 224, 224, 0.5);
  border-radius: 33px;
  padding: 0px !important;
  box-shadow: 8px 8px 25px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
}

.card-body {
  box-shadow: 8px 8px 25px rgba(0, 0, 0, 0.5);
  border-radius: 33px;
}

@media only screen and (orientation: landscape) and (min-width: 768px) {
  .card{
      right: 30%;
  }
}


.form {

  font-size: 14px !important;
}


.btn_login {
  max-width: 350px;
  height: 50px;
  border-radius: 30px;
  background-color: #FBA834;
  font-size: 16px;
  border: none !important;
  color: #292929;
  font-weight: 800;
  letter-spacing: 1px;
}

.btn_changePassword:hover {
  background-color: #FF9500;
  color: #292929;
  font-weight: 800;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
}



#sub-heading
{
  font-size: 18px;
  text-align: left !important;
  margin-bottom: 15px;
  opacity: 75%;
  max-width: 350px !important;
  margin: auto;
}



