/* Poppins Regular */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Poppins Bold */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* Poppins Italic */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

/* Poppins Bold Italic */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

/* Poppins Light */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

/* Poppins Light Italic */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

/* Poppins Medium */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

/* Poppins Medium Italic */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

/* Poppins SemiBold */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

/* Poppins SemiBold Italic */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

/* Poppins ExtraBold */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

/* Poppins ExtraBold Italic */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

/* Poppins Thin */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

/* Poppins Thin Italic */
@font-face {
    font-family: 'Poppins';
    src: url('../../view/src/Assets/fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}


body{
    font-family: 'Poppins';
}

