.accordion-button:focus {
  box-shadow: none;
  outline: none;
  background-color: #4CC8F7;
}
.form_Control {
  border: none;
  height: 40px;
  font-size: 14px;
  background-color: none;
  
}

.table th {
  font-weight: normal; 
  padding: 8px; 
}
.form_label{
  border: none;
}
.btn_edit1{
  background-color: #FBA834;
  border: 0px solid #333;
  font-weight: 500;
  transition: all ease 0.2s;
  height: 40px;
  margin-left: 25%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.btn_edit1:hover{
  background-color: #4CC8F7 !important;
  color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25); 
}
.popup{
  position: relative;
  margin: 20px;
}
.editPopup{
  overflow: scroll;
}

