body {
  background-color: #292929;
}

.card {
  background-color: #1e1e1e;
  max-height: 500px;
  border: 1px solid rgba(224, 224, 224, 0.5);
  border-radius: 33px;
  padding: 0px !important;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
}

.card-body{
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.5);
  border-radius: 33px;
}

@media only screen and (orientation: landscape) and (min-width: 768px) {
  .card {
    right: 30%;
  }
}

.form {
  font-size: 14px !important;
}

.btn_login {
  max-width: 350px !important;
  height: 50px;
  border-radius: 30px;
  background-color: #FBA834;
  font-size: 16px;
  border: none !important;
  color: #292929;
  font-weight: 800;
  letter-spacing: 1px;
}

.btn_login:hover {
  background-color: #FF9500; /* Slightly darker shade */
  color: #292929;
  font-weight: 800;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4); /* Add a shadow effect */
}

.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  translate: 0 -50%;
  font-size: 16px;
  color: white;
}


.tb_passwordFieldInput:focus, .tb_userNameFieldInput:focus {
  outline: none !important;
  color: #333333;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.2);
}

.tb_passwordFieldInput::placeholder, .tb_userNameFieldInput::placeholder {
  font-size: 14px;
  opacity: 25% !important;
  color: #e0e0e0 !important;
}

#sub-heading {
  font-size: 18px;
  text-align: left !important;
  margin-bottom: 15px;
  opacity: 75%;
  max-width: 350px !important;
  color: #e0e0e0;
}

.tb_passwordFieldInput::-ms-value, .userNameFieldInput::-ms-value {
  font-size: 14px !important;
}
.username{
  margin-bottom: 5px;
}
.password {
  margin-bottom: 5px;
}
.background{
  background-color: #6a6a6a;
}