.accordion-button:focus {
  box-shadow: none;
  outline: none;
  background-color: #4CC8F7;
}
.form_Control {
  border: none;
  height: 45px;
  font-size: 14px;
  background-color: none;
  
}
.textarea1{
  border: none;
  height: 45px;
  font-size: 14px;
  background-color: none;
}
.table th {
  font-weight: normal; 
  padding: 8px; 
}

.form_label{
  border: none;
}

.btn_edit{
  background-color: #FBA834 !important;
  border: 0px solid #333;
  font-weight: 500 !important;
  transition: all ease 0.2s;
  float: center;
  height: 40px;
}

.btn_edit:hover{
  background-color: #4CC8F7 !important;
  color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25); 
}
